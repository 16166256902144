$header-height: 70px;

header {
	padding: 10px;
	height: $header-height;

	.level-right {
		.button {
			&.is-danger {
				i {
					color: #cc0f35;
				}
			}
		}
	}

	.level-left {
		img {
			max-width: 150px;
		}
	}

	@media screen and (max-width: 768px) {
		.level-left + .level-right {
			margin-top: 0.5rem;
		}
	}
}

body.dark-mode {
	header {
		.button {
			background-color: #3d3c3c !important;
		}

		.button.is-danger.is-light i {
			color: #d3a1ab;
		}

		.button.is-link.is-light i {
			color: #838383;
		}
	}
}
