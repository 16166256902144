$loading-size: 10px;
$loading-size-large: 20px;

.loading-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	min-width: 200px;

	.loading {
		display: flex; // Added to layout children in a row
		justify-content: flex-start; // Center children horizontally
		align-items: center; // Center children vertically
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(1);
		backface-visibility: hidden;
		transform-origin: 0 0; /* see note above */

		div {
			position: relative;
			width: $loading-size;
			height: $loading-size;
			margin: calc($loading-size / 3);
			border-radius: 50%;
			box-sizing: content-box;
			animation: loadingCirclesAnimation 1.1764705882352942s cubic-bezier(0.3, 0, 0.7, 1) infinite;
		}

		div:nth-of-type(4n + 1) {
			background: #85a2b6;
		}

		div:nth-of-type(4n + 2) {
			background: #bbcedd;
		}

		div:nth-of-type(4n + 3) {
			background: #dce4eb;
		}

		div:nth-of-type(4n + 1) {
			animation-delay: -0.4411764705882353s;
		}

		div:nth-of-type(4n + 2) {
			animation-delay: -0.29411764705882354s;
		}

		div:nth-of-type(4n + 3) {
			animation-delay: -0.14705882352941177s;
		}
	}
	.sentence-animation {
		overflow: hidden; // Ensures sliding content is trimmed
		text-align: center; // Center the sentence below the loading animation
		display: flex;
		justify-content: center; // Ensure the flex container is centered

		.sentence {
			display: flex;
			justify-content: center; // Center the text and dots
			min-width: 0; // Prevent flex child from growing beyond its content
			animation: loadingSentencesAnimation 0.5s ease-out; // Adjust duration as needed
		}

		.dots {
			visibility: hidden; // Hide dots by default
			min-width: 24px; // Allocate space for three dots
			text-align: left; // Align dots to the left within the allocated space

			&::before {
				content: attr(data-dots); // Use data attribute for dots
				visibility: visible; // Make visible only the content (dots)
			}
		}
	}

	&.loading-large {
		.loading {
			div {
				width: $loading-size-large;
				height: $loading-size-large;
				margin: calc($loading-size-large / 3);
			}
		}

		.sentence-animation {
			font-weight: bold;
			.sentence,
			.dots {
				color: #85a2b6;
			}
		}
	}
}

.rtl {
	.sentence-animation {
		direction: rtl;
		justify-content: flex-start;
		width: 100%;
		.dots {
			text-align: right; // Align dots to the left within the allocated space
		}
	}

	.loading {
		justify-content: flex-end;
	}
}

@keyframes loadingCirclesAnimation {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@keyframes loadingSentencesAnimation {
	from {
		transform: translateY(-100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}
