.chat {
	border-radius: 10px;
	border: 1px solid #e5e7eb;
	background: #fff;
	text-align: center;
	display: flex;
	flex-flow: column;
	overflow: hidden;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	height: calc(100vh - $header-height - 20px);

	.header {
		padding: 1.5rem;
		text-align: center;
		color: #020817;
		font-size: 1.9rem;
		font-weight: bold;
	}

	.messages {
		width: 100%;
		height: 400px;
		overflow: auto;
		display: flex;
		flex-direction: column;
		padding: 0px 1.5rem;
		flex-grow: 1;

		.message-container {
			width: 100%;
			margin-bottom: 10px;

			.bot-wrapper {
				display: flex;
				align-items: flex-end; /* Align items to the bottom */

				img.tracer-icon {
					height: 30px;
					margin-right: 10px;
				}
			}

			.message {
				padding: 0.75rem;
				min-width: 40px;
				font-size: 1.12rem;
				font-weight: 600;
				max-width: 85%;
				text-align: left;

				&.rtl {
					direction: rtl;
					text-align: right;
				}

				&.bot-message {
					float: left;
					border-radius: 1rem 1rem 1rem 0;
					background: rgb(244, 244, 245);
					color: black;

					&.error {
						border-color: hsl(348deg, 86%, 61%);
						background-color: #feecf0;

						span {
							color: #cc0f35;
							font-size: inherit;
							a {
								font-size: inherit;
							}
						}
					}
				}

				&.user-message {
					float: right;
					border-radius: 1rem 1rem 0 1rem;
					background: rgb(74 197 242 / 22%);
					color: black;
				}

				div,
				p {
					font-size: 1.12rem;
				}

				ul,
				ol {
					padding: 5px 15px;
				}

				table {
					border: 2px solid #dddddd;

					thead tr {
						background-color: #e7e4e4;
					}

					th,
					td {
						padding: 5px;
						font-size: 0.9rem;
					}

					tbody tr {
						border-bottom: 1px solid #dddddd;
					}

					tbody tr:nth-of-type(even) {
						background-color: #f3f3f3;
					}

					tbody tr:last-of-type {
						border-bottom: 2px solid #dddddd;
					}

					tr th:not(:last-child),
					tr td:not(:last-child) {
						border-right: 1px solid #dddddd; /* Adjust the color and size as needed */
					}
				}
			}
		}
	}

	.input-area {
		position: relative;

		form {
			textarea {
				font-size: 1.12rem;
				border: 0;
				border-radius: 0;
				resize: none;

				border-top: 1px solid #eee;
				box-shadow: none;
				box-sizing: border-box;
				opacity: 1;
				outline: none;
				padding: 16px 52px 16px 16px;
				width: 100%;
				line-height: 2rem;
				height: 60px;
				max-height: 300px;
				-webkit-appearance: none;
			}

			input:focus {
				outline: none;
			}

			button {
				background-color: transparent;
				border: 0;
				border-bottom-right-radius: 10px;
				box-shadow: none;
				cursor: pointer;
				opacity: 1;
				outline: none;
				padding: 20px 16px;
				position: absolute;
				right: 0;
				bottom: 0;

				i {
					font-size: 25px;
					color: #000;
				}

				&.disabled {
					cursor: default;
					i {
						font-size: 25px;
						opacity: 0.1;
					}
				}
			}
		}
		&.rtl {
			direction: rtl;

			textarea {
				padding: 16px 16px 16px 52px;
			}

			input:focus {
				outline: none;
			}

			button {
				right: auto;
				left: 0;
			}
		}
	}
}

body.dark-mode {
	.chat {
		background: #212121;
		border-color: #424242;

		.header {
			color: #cecece;
		}

		.user-message {
			color: #fff !important;
		}
		.bot-message {
			background: #353535 !important;
			color: #fff !important;

			div,
			p,
			span,
			li {
				color: #fff !important;
			}

			&.error span {
				color: #e28a9c !important;
			}
		}
		table td {
			color: #fff;
		}

		textarea {
			background: #212121;
			color: #fff;
			border-color: #424242;
		}

		.input-area form button i {
			color: #cecece;
		}
	}
}
