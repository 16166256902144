body,
html {
	background: #f8fafc;
}

body.dark-mode {
	background: #171717;
}

.main {
	@media screen and (max-width: 768px) {
		padding: 0 10px;
	}

	.page-title {
		margin: 40px auto 40px;
	}
}
